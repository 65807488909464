import { createThemes } from '@babylon/ui-kit-styles/common/themes';
import { ThemeId } from '@babylon/ui-kit-styles/types/theme';

import tourMundialCarouselProductStyles from './styles/tourMundial';

const carouselProductThemes = createThemes({
	[ThemeId.tourMundial]: tourMundialCarouselProductStyles,
});

export default carouselProductThemes;
