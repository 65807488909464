import { Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { CarouselProductStyles } from '../types';

const tourMundialCarouselProductStyles: CarouselProductStyles = {
	Wrapper: () => css`
		&:nth-child(2n + 1) {
			background-color: ${({ theme }) => theme.colors.status.complementary5};
			${Padding({ y: '58px' })}
		}

		& > div {
			position: relative;
			max-width: 1500px;
			width: 100%;
			margin: 0px auto 0px;
			padding: 0px 20px;
		}
	`,
};

export default tourMundialCarouselProductStyles;
