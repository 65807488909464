import CarouselCardSimple from '@babylon/ui-kit-carousels/components/carousel-card-simple';
import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic';
import CarouselHeader from '@babylon/ui-kit-carousels/components/carousel-header';
import CarouselOffersCta from '@babylon/ui-kit-carousels/components/carousel-offers-cta';
import CarouselProduct from '@babylon/ui-kit-carousels/components/carousel-product';
import BannersHighlights from '@babylon/ui-kit-common/components/others/banners-highlights';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs';
import Comarketing from '@babylon/ui-kit-common/components/others/comarketing';
import Features from '@babylon/ui-kit-common/components/others/features';
import GridProduct from '@babylon/ui-kit-common/components/others/grid-product';
import HeaderBannerSimple from '@babylon/ui-kit-common/components/others/header-banner-simple';
import ImagesList from '@babylon/ui-kit-common/components/others/images-list';
import LinksSEO from '@babylon/ui-kit-common/components/others/links-seo';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import SearcherFerryHotelDesktop from '@babylon/ui-kit-searchers/components/searchers/searcher-ferry-hotel/views/desktop';
import SearcherFlightHotelDesktop from '@babylon/ui-kit-searchers/components/searchers/searcher-flight-hotel/views/desktop';
import SearcherMultiPak from '@babylon/ui-kit-searchers/components/searchers/searcher-multi-pak';
import SearcherTabs from '@babylon/ui-kit-searchers/components/searchers/searcher-tabs';
import SearcherTouDesktop from '@babylon/ui-kit-searchers/components/searchers/searcher-tou/views/desktop';
import SearcherTrainHotelDesktop from '@babylon/ui-kit-searchers/components/searchers/searcher-train-hotel/views/desktop';
import LinksBar from '@babylon/ui-kit-structures/components/others/links-bar/views/desktop';
import TabsCarouselProductCardCir from '@babylon/ui-kit-wrappers/components/circuitos/tabs-carousel-product-card';
import Multisearcher from '@babylon/ui-kit-wrappers/components/others/multisearcher';
import TabsCarouselProductCardFlat from '@babylon/ui-kit-wrappers/components/others/tabs-carousel-product-card-flat';
import TextHTMLCollapsePackagesDesktop from '@babylon/ui-kit-wrappers/components/packages/text-html-collapse';
import TextScroll from '@babylon/ui-kit-wrappers/components/packages/text-scroll';
import type { ComponentProps } from 'react';

import CarouselList from '@/components/carousel-list';
import ProductLei from '@/components/product-lei';

/* HYDRATION ON DEMAND  */

// order
export const DEFAULT_ORDER_DESKTOP = [
	'--DEFAULT_ORDER_DESKTOP--',
	'banners',
	'headerCarousel',
	'searcherTabs',
	'searcherTabsRxjs',
	'searcherPak',
	'breadcrumbs',
	'catalogueRxjs',
	'productCardGrid',
	'productCardTabs',
	'productCir',
	'productTabRxjs',
	'productTTOO',
	'featuresBanner',
	'features',
	'carouselList',
	'productCarouselListRxjs',
	'travelSafe',
	'productLei',
	'carouselOffers',
	'destinationGuide',
	'conditions',
	'links',
	'topButton',
	'traveltoolWidgets',
	'paymentList',
];

/* Searchers */
export const SearcherMultiPakHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherMultiPak>>()(SearcherMultiPak);
export const SearcherTabsHydrated = withHydrationOnDemand<ComponentProps<typeof SearcherTabs>>()(SearcherTabs);
export const MultisearcherTabssHydrated = withHydrationOnDemand<ComponentProps<typeof Multisearcher>>()(Multisearcher);
export const SearcherFlightHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherFlightHotelDesktop>>()(SearcherFlightHotelDesktop);
export const SearcherFerryHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherFerryHotelDesktop>>()(SearcherFerryHotelDesktop);
export const SearcherTrainHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherTrainHotelDesktop>>()(SearcherTrainHotelDesktop);
export const SearcherTouHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherTouDesktop>>()(SearcherTouDesktop);

// Carousel
export const CarouselHeaderHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselHeader>>()(CarouselHeader);
export const HeaderBannerSimpleHydrated =
	withHydrationOnDemand<ComponentProps<typeof HeaderBannerSimple>>()(HeaderBannerSimple);
export const BreadcrumbsHydrated = withHydrationOnDemand<ComponentProps<typeof Breadcrumbs>>()(Breadcrumbs);
export const BannersHighlightsHydrated =
	withHydrationOnDemand<ComponentProps<typeof BannersHighlights>>()(BannersHighlights);
export const ComarketingHydrated = withHydrationOnDemand<ComponentProps<typeof Comarketing>>()(Comarketing);
export const FeaturesHydrated = withHydrationOnDemand<ComponentProps<typeof Features>>()(Features);
export const CarouselOffersCtaHydrated =
	withHydrationOnDemand<ComponentProps<typeof CarouselOffersCta>>()(CarouselOffersCta);
export const LinksSEOHydrated = withHydrationOnDemand<ComponentProps<typeof LinksSEO>>()(LinksSEO);
export const ConditionsHydrated = withHydrationOnDemand<ComponentProps<typeof TextHTMLCollapsePackagesDesktop>>()(
	TextHTMLCollapsePackagesDesktop
);
export const TabsCarouselProductCardHydrated =
	withHydrationOnDemand<ComponentProps<typeof TabsCarouselProductCardFlat>>()(TabsCarouselProductCardFlat);
export const GridProductHydrated = withHydrationOnDemand<ComponentProps<typeof GridProduct>>()(GridProduct);
export const CarouselProductHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselProduct>>()(CarouselProduct);
export const CarouselGenericHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselGeneric>>()(CarouselGeneric);
export const CarouselListHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselList>>()(CarouselList);
export const CarouselCardSimpleHydrated =
	withHydrationOnDemand<ComponentProps<typeof CarouselCardSimple>>()(CarouselCardSimple);
export const ProductLeiHydrated = withHydrationOnDemand<ComponentProps<typeof ProductLei>>()(ProductLei);
export const LinksBarHydrated = withHydrationOnDemand<ComponentProps<typeof LinksBar>>()(LinksBar);
export const ImagesListHydrated = withHydrationOnDemand<ComponentProps<typeof ImagesList>>()(ImagesList);
export const TextScrollHydrated = withHydrationOnDemand<ComponentProps<typeof TextScroll>>()(TextScroll);
export const TabsCarouselProductCardCirHydrated =
	withHydrationOnDemand<ComponentProps<typeof TabsCarouselProductCardCir>>()(TabsCarouselProductCardCir);
