import Tags from '@babylon/ui-kit-structures/components/others/tags';
import useIsLiverpool from '@babylon/ui-kit-styles/hooks/useIsLiverpool';
import type { FC } from 'react';

import type { CarouselCategoryProps } from '../../types';
import { CAROUSEL_MOBILE_PROPS } from '../../utils/constants';
import { getCardsCarousel } from '../../utils/getCardsCarousel';
import * as S from './styled';

const CarouselCategoryMobile: FC<CarouselCategoryProps> = ({ props }) => {
	const mobileCards = getCardsCarousel(props, true);
	const { isLiverpool } = useIsLiverpool();

	const carouselProps = {
		heading: {
			title: {
				content: props?.title?.title ? props.title.title : '',
			},
		},
		items: mobileCards,
		...CAROUSEL_MOBILE_PROPS,
	};

	const links = props?.items?.length ? props.items.map(item => ({ ...item.link, content: item.title })) : [];

	return (
		<div data-testid='CarouselCategory'>
			{carouselProps && <S.Carousel {...carouselProps} />}
			{props?.otherCategories && !isLiverpool && <S.OtherCategories>{props.otherCategories}</S.OtherCategories>}
			{links && !isLiverpool && <Tags items={links} />}
		</div>
	);
};

export default CarouselCategoryMobile;
