import type { CardSimpleProps } from '@babylon/ui-kit-cards/components/cards/card-simple/types';

import type { CarouselCategoryWithConfigProps } from '@/components/carousel-category/types';

import * as S from '../views/desktop/styled';
import { CARD_SIMPLE_DESKTOP_PROPS, CARD_SIMPLE_MOBILE_PROPS } from './constants';

export function getCardsCarousel(props: CarouselCategoryWithConfigProps, isMobile: boolean) {
	const sizes = isMobile ? CARD_SIMPLE_MOBILE_PROPS : CARD_SIMPLE_DESKTOP_PROPS;
	const cards =
		props?.items && props?.items?.length
			? props.items.map((item: CardSimpleProps) => (
					<S.StyledCardSimple
						{...sizes}
						key={JSON.stringify(item)}
						{...item}
						config={props.config}
						titleTag={isMobile ? 'p' : 'h3'}
					/>
				))
			: [];
	return cards;
}
