import { Title, TitleWrapper } from '@babylon/ui-kit-carousels/components/carousel-generic/views/desktop/styled';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { CarouselCategoryStyles } from '../types';

const tourMundialCarouselCategoryStyles: CarouselCategoryStyles = {
	Carousel: () => css`
		${TitleWrapper} {
			${Margin({ bottom: '24px' })}
			${Title} {
				font-family: ${({ theme }) => theme.font.family.primary};
				${FontMixin({ size: 'largest', height: 'largest', weight: 'bold' })};
				text-transform: uppercase;
			}
		}

		.splide__track {
			${Padding({ bottom: '12px' })}
		}

		.splide__pagination .splide__pagination__page {
			opacity: 1;
			background: unset;
			&::before {
				background: ${({ theme }) => theme.colors.grays.darkest};
			}
			&.is-active {
				background: unset;
				width: 12px;
				&:before {
					background: ${({ theme }) => theme.colors.primary.base};
				}
			}
		}
	`,
	StyledCardSimple: () => css`
		height: 320px;
		box-shadow: 0px 8px 16px 0px ${({ theme }) => theme.colors.black.higher};
		figure {
			height: 100%;
		}
		figure > img {
			height: 100%;
		}
		a {
			${FlexMixin({ justify: 'flex-end', align: 'flex-start' })};
			${Padding({ all: '16px' })}
			background-color: unset;

			p {
				background-color: ${({ theme }) => theme.colors.white.base};
				color: ${({ theme }) => theme.colors.grays.darker};
				text-transform: capitalize;
				${FontMixin({ weight: 'bold' })};
				${Margin({ bottom: 'unset' })};
				${Padding({ x: '20px', y: '12px' })}
			}
		}
	`,
};

export default tourMundialCarouselCategoryStyles;
